import * as React from "react"

const Logo = () => (
  <svg viewBox="0 -25 140 100">
    <title>LekoArts</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 51.9 0.001 L 67.5 0.001 L 67.5 66.001 L 57.6 66.001 L 57.6 27.801 L 58.4 9.601 L 58.3 9.601 L 38.2 66.001 L 29.3 66.001 L 9.2 9.601 L 9.1 9.601 L 9.9 27.801 L 9.9 66.001 L 0 66.001 L 0 0.001 L 15.9 0.001 L 29.2 38.201 L 33.8 53.901 L 34 53.901 L 38.7 38.201 L 51.9 0.001 Z M 113.5 66.001 L 87.1 66.001 L 87.1 0.001 L 113.1 0.001 A 46.848 46.848 0 0 1 119.287 0.38 Q 126.153 1.298 130.062 4.434 A 13.814 13.814 0 0 1 130.5 4.801 A 15.718 15.718 0 0 1 135.727 14.499 A 22.854 22.854 0 0 1 136 18.101 Q 136 23.301 133 27.351 A 12.671 12.671 0 0 1 127.084 31.708 A 17.694 17.694 0 0 1 124.2 32.501 L 124.2 32.601 A 18.819 18.819 0 0 1 128.93 33.949 A 14.166 14.166 0 0 1 134.15 37.851 Q 137.7 42.101 137.7 47.701 A 18.751 18.751 0 0 1 136.754 53.792 A 15.617 15.617 0 0 1 131.5 61.101 A 20.224 20.224 0 0 1 125.138 64.407 Q 122.262 65.341 118.761 65.727 A 47.996 47.996 0 0 1 113.5 66.001 Z M 97.6 37.301 L 97.6 57.101 L 114 57.101 Q 120.3 57.101 123.45 54.501 A 8.476 8.476 0 0 0 126.48 48.956 A 12.365 12.365 0 0 0 126.6 47.201 A 11.235 11.235 0 0 0 126.193 44.094 A 8.274 8.274 0 0 0 123.45 39.901 Q 121.122 37.979 117.073 37.478 A 25.058 25.058 0 0 0 114 37.301 L 97.6 37.301 Z M 97.6 9.001 L 97.6 28.401 L 112.3 28.401 A 23.999 23.999 0 0 0 116.899 28 Q 124.9 26.43 124.9 18.701 Q 124.9 10.147 115.103 9.136 A 27.354 27.354 0 0 0 112.3 9.001 L 97.6 9.001 Z"
    />
  </svg>
)

export default Logo
